import {
    EFormType,
    ESurveyType,
} from '../../../../../shared/interfaces/backend/contract.interface';
import {
    IPhotoStructureItem,
    photoStructureInnerDefault,
    photoStructureOuterDefault,
} from '../../../../../shared/photos/photos';
import { ISurvey } from '../../../interfaces/contract/survey.model';

export const photoDefaultOuterMax = 15;
export const photoDefaultInnenMax = 14;

export const getMaxSlotsForSurvey = (survey: ISurvey) => {
    if (survey.type === 'Aussenbesichtigung') return photoDefaultOuterMax;
    if (survey.type === 'Innenbesichtigung') return photoDefaultInnenMax;

    return 15;
};

export const getPhotoStructure = (
    surveyType: ESurveyType,
    formType: EFormType
): IPhotoStructureItem[] | null => {
    switch (formType) {
        case EFormType.Standard:
        case EFormType['DZ HYP']:
        case EFormType.Sachwertrichtlinie:
        default:
            if (surveyType === ESurveyType.Outer) return photoStructureOuterDefault;
            if (surveyType === ESurveyType.Inner) return photoStructureInnerDefault;
    }

    return null;
};

const usePhotoStructure = () => {
    const getStructure = (
        surveyType: ESurveyType,
        formType: EFormType
    ): IPhotoStructureItem[] | null => {
        switch (formType) {
            case EFormType.Standard:
            case EFormType['DZ HYP']:
            case EFormType.Sachwertrichtlinie:
            default:
                if (surveyType === ESurveyType.Outer) return photoStructureOuterDefault;
                if (surveyType === ESurveyType.Inner) return photoStructureInnerDefault;
        }

        return null;
    };

    return { getStructure };
};
export default usePhotoStructure;
